<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <div class="pad-container flx-row ali-c" style="padding:12px 0">
        <el-select
          placeholder="请选择门店"
          class="search-input ml-15"
          v-model="storeId"
        >
          <el-option
            v-for="item in shopList"
            :key="item.id"
            :label="item.storeName"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input
          placeholder="请输入商品编码"
          class="search-input"
          v-model.trim="searchObj.goodsCodeLst"
          clearable
        ></el-input>
        <el-input
          placeholder="请输入商品条形码"
          class="search-input"
          v-model.trim="searchObj.goodsBarcodeLst"
          clearable
        ></el-input>
        <el-input
          placeholder="请输入商品名称"
          class="search-input ml-15"
          v-model.trim="searchObj.goodsName"
          clearable
        ></el-input>
        <el-cascader
          class="ml-15"
          v-model="classifyId"
          :options="classifyList"
          :props="props"
          :show-all-levels="false"
          placeholder="请选择商品分类"
          @change="classifyIdChange()"
        ></el-cascader>
        <el-select
          placeholder="请选择上下架状态"
          class="search-input ml-15"
          v-model="searchObj.status"
        >
          <el-option
            v-for="(item, index) in goodsStatusOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search"  class="ml-15"  @click="search"  >查询</el-button>
        <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        <el-button type="primary" icon="el-icon-plus" class="ml-15" @click="addNewGood">新增</el-button>
      </div>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-12">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container" style="padding:12px 0">
          <!-- <el-tabs
            v-model="activePart"
            @tab-click="changeTab"
            v-if="!storeFlag"
          >
            <el-tab-pane
              :label="item.storeName"
              :name="item.id + ''"
              v-for="(item, index) in shopList"
              :key="index"
            >
            </el-tab-pane>
          </el-tabs> -->
          <!-- 表格 -->
          <div>
            <el-table class="member-table" :data="tableData" border>
              <el-table-column label="序号" width="50" align="center">
                <template slot-scope="scope">{{
                  scope.$index + 1 + (pageNum - 1) * pageSize
                }}</template>
              </el-table-column>
              <el-table-column label="商品条码(UPC)"  prop="goodsBarcode"  width="140" align="center"></el-table-column>
              <el-table-column  label="商品编码"  prop="goodsCode" width="80" align="center"></el-table-column>
              <el-table-column label="商品名称" prop="goodsName" width="200">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="light" :content="scope.row.goodsName" placement="top-start">
                    <span class="goodsName">{{scope.row.goodsName}}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column  label=" 商品名称（来源ERP）"  prop="goodsSubhead" width="200">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="light" :content="scope.row.goodsSubhead" placement="top-start">
                    <span class="goodsName">{{scope.row.goodsSubhead}}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <!-- <el-table-column  label="一级分类"  prop="classifyName"></el-table-column>
              <el-table-column  label="二级分类" prop="secondClassifyName"></el-table-column> -->
              <el-table-column  label="实价(元)"  prop="actualPrice" width="80" align="center"></el-table-column>
              <el-table-column  label="原价(元)"  prop="originalPrice" width="80" align="center"></el-table-column>
              <el-table-column  label="月销量"  prop="monthSales" width="65" align="center"></el-table-column>
              <el-table-column  label="库存" prop="stock" width="60" align="center"></el-table-column>
              <el-table-column  label="商品角标"  prop="cornerMarkName" align="center"></el-table-column>
              <el-table-column  label="重量(g)"  prop="goodsWeight" width="80" align="center"></el-table-column>
              <el-table-column label="上架状态" prop="status" width="100">
                <template slot-scope="scope">
                  <div class="flx-row">
                    <el-switch
                      v-model="scope.row.status"
                      :active-value="1"
                      :inactive-value="0"
                      @change="toggle(scope.row)"
                    ></el-switch>
                    <span class="ml-5">{{
                      scope.row.status ? "上架" : "下架"
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="140" align="center">
                <template slot-scope="scope">
                  <div class="flx-row ali-c">
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="
                        handleEdit(scope.row.goodsId, scope.row.storeId)
                      "
                    >
                      <i class="el-icon-edit-outline"></i>
                      编辑
                    </el-link>
                    <el-popover
                      
                      placement="top"
                      width="160"
                      v-model="scope.row.visible">
                      <p>确定删除该商品吗？</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="deleteSure(scope.row,0)">取消</el-button>
                        <el-button type="primary" size="mini" @click="deleteSure(scope.row,1)">确定</el-button>
                      </div>
                      <el-button type="text" size="mini" icon="el-icon-delete" slot="reference" style="margin:0 12px">删除</el-button>
                    </el-popover>
                    
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  // queryGoodsType,
  listOfPage,//获取门店列表
  // delGoodsFreshInfo,
  // upAndDownGoods,
  // queryFJGoodsList,
  saveOrUpdate,//保存或者更新
  goodsSupermarkertPage,//查询商品分页
  deleteByGoodId,//根据商品id删除商品
  downOrUp,//上下架商品
  getInfoById,//根据商品id获取商品详情
} from "@/api/market/goods_list";
import { treeGoodsClassifyList } from "@/api/online_department_store/categories_manage";
export default {
  name: "goods_list",
  components: {
    Pagination,
  },
  data() {
    return {
      isCompleteOptions: [
        {
          name: "是",
          value: "1",
        },
        {
          name: "否",
          value: "0",
        },
      ],
      props: {
        label: "classifyName",
        value: "id",
        children: "children",
      },
      activePart: "",
      // 顶部搜索栏数据
      searchObj: {
        goodsCodeLst: "",
        goodsName: "",
        status: "",
        goodsBarcodeLst:'',//条码
        // classifyId:""
        // isComplete: ""
      },
      classifyId: [],
      // 一级分类下拉选项
      classifyList: [],
      // 二级分类下拉选项
      secondClassifyOptions: [
        { id: 0, name: "二级分类1" },
        { id: 1, name: "二级分类2" },
        { id: 2, name: "二级分类3" },
      ],
      // 商品状态下拉选项
      goodsStatusOptions: [
        { id: 0, name: "下架" },
        { id: 1, name: "上架" },
      ],
      // 是否完善信息下拉选项
      completeInfoOptions: [
        { id: 0, name: "是" },
        { id: 1, name: "否" },
      ],

      // 拼团表格数据内容
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      shopList: [],
      upFlag: true,
      storeId: "",
      storeFlag: false,
    };
  },
  mounted() {
    if (this.$store.getters.storeId) {
      this.storeId = this.$store.getters.storeId;
      this.storeFlag = true;
      this.getGoodsList();
    } else {
      this.getStoreList();
    }
    this.getClassifyList();
  },
  methods: {
    // 获取分类列表，启用的是2022年5月份的牵牛花分类树形接口
    getClassifyList() {
      let param = {
        goodsType:2,//2商超商品，3百货商品
        classifyName:'',//查询全部，精准查询类名传空
        // pageNum:1,不传
        // pageSize:10,不传
      }
      treeGoodsClassifyList(param).then((res) => {
        this.classifyList = res.data.body;
        // console.log(this.classifyList)
      });
    },
    //获取门店列表
    getStoreList() {
      listOfPage().then((res) => {
        this.shopList = res.data.body.list;
        console.log(this.shopList)
        this.storeId = this.shopList[0].id;
        this.getGoodsList();
      });
    },
    // 删除和取消删除
    deleteSure(e,type){
      if(type == 1){
        deleteByGoodId(e.goodsId).then(res=>{
          this.$message({
          message: '您已成功删除商品！',
          type: 'success'
        });
        }).catch(err=>{
          this.$message({
            message: '商品删除失败了！',
            type: 'error'
          });
        }).finally(()=>{
          e.visible = false
          this.getGoodsList()
        })
      }else{
        e.visible = false
      }
    },
    // 编辑
    handleEdit(id, storeId) {
      this.$router.push({ name: "Goods_new", query: { id, storeId } });
    },
    // 上下架验证
    toggle(row) {
      console.log('用户手动切换上下架操作')
      let flag = row.status;
      row.status = row.status ? 0 : 1;
      if (row.status) {
        this.$confirm("确定要下架吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          this.toggleReq(row, flag);
        });
      } else {
        this.toggleReq(row, flag);
      }
    },
    // 上下架请求
    toggleReq(row, flag) {
      let data = {
        goodsId: row.goodsId,
        status: row.status ? 0 : 1,
      };
      downOrUp(data).then((res) => {
        row.status = flag ? 1 : 0;
        this.getGoodsList();
      });
    },
    // 切换tab
    // changeTab(tab) {
    //   this.storeId = tab.name;
    //   this.pageNum = 1;
    //   this.getGoodsList();
    // },
    //数据初始化
    getGoodsList() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        storeId: Number(this.storeId),//门店id
        classifyId:this.classifyId,//分类id
        goodsType:1,//商品类型，默认为1，富基商品，2是生鲜暂时不涉及
        goodsCodeLst:this.searchObj.goodsCodeLst.length > 0 ? this.searchObj.goodsCodeLst.split(',') : [],//编码
        goodsBarcodeLst:this.searchObj.goodsBarcodeLst.length > 0 ? this.searchObj.goodsBarcodeLst.split(',') : [],//条码
        status:this.searchObj.status,//上下架
        goodsName:this.searchObj.goodsName,//商品名称
      };
      // data = Object.assign(data, this.searchObj);
      let length = this.classifyId.length;
      if (length) {
        data.classifyId = this.classifyId[length - 1];
      }else{
        data.classifyId = ''
      }
      goodsSupermarkertPage(data).then((res) => {
        let data = res.data.body;
        this.total = data.total;
        for(let i= 0;i<data.list.length;i++){
          data.list[i].visible = false
        }
        this.tableData = data.list;
      });
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.getGoodsList();
    },
    // 重置搜索关键词
    reset() {
      for (let key in this.searchObj) {
        this.searchObj[key] = "";
      }
      this.classifyId = [];
      this.search();
    },
    // 页面列表 pageNum pageSize获取
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.getGoodsList();
    },
    // 分类切换
    classifyIdChange(){
      console.log(this.classifyId)
    },
    // 新增商品
    addNewGood(){
      this.$router.push({ name: "Goods_new", query:''});
    }
  },
  filters: {
    statusFilter(val) {
      switch (val) {
        case 0:
          return "等待中";
        case 1:
          return "进行中";
        case 2:
          return "已结束";
        default:
          return "状态未知";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mt-12{
  margin-top: 12px;
}
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.el-avatar /deep/ img {
  width: 100%;
}
.goodsName{
  width: 100%;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis
}
</style>
